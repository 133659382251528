var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app flex-row align-items-center guest" }, [
    _c(
      "div",
      { staticClass: "container pt-4 pb-4" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "8", lg: "10" } },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-card-body", { staticClass: "p-4" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.validateBeforeSubmit.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "Login" } } },
                            [
                              _c("img", {
                                staticClass:
                                  "mx-auto d-block mb-4 navbar-brand-full",
                                attrs: {
                                  src: "img/brand/invoice-logo-2019.png",
                                  height: "95",
                                  alt: "RTNEST Inc. Logo"
                                }
                              })
                            ]
                          ),
                          _c("h1", { staticClass: "h2" }, [
                            _vm._v("Register Well Site Supervisor")
                          ]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-3 mb-0 pb-0" },
                            [_vm._v("Name")]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-1" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "icon-user"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "fname",
                                          placeholder: "First Name"
                                        },
                                        model: {
                                          value: _vm.fname,
                                          callback: function($$v) {
                                            _vm.fname = $$v
                                          },
                                          expression: "fname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("fname"),
                                        expression: "errors.has('fname')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("fname"),
                                          expression: "errors.has('fname')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The first name is required.")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-1" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "icon-user"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "lname",
                                          placeholder: "Last Name"
                                        },
                                        model: {
                                          value: _vm.lname,
                                          callback: function($$v) {
                                            _vm.lname = $$v
                                          },
                                          expression: "lname"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("lname"),
                                        expression: "errors.has('lname')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("lname"),
                                          expression: "errors.has('lname')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The last name is required.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-1 mb-0 pb-0" },
                            [_vm._v("Business Information")]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "company_name",
                                          placeholder: "Company Name"
                                        },
                                        model: {
                                          value: _vm.company_name,
                                          callback: function($$v) {
                                            _vm.company_name = $$v
                                          },
                                          expression: "company_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("company_name"),
                                        expression: "errors.has('company_name')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("company_name"),
                                          expression:
                                            "errors.has('company_name')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The company name is required.")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "addressline1",
                                          placeholder: "Address Line 1"
                                        },
                                        model: {
                                          value: _vm.addressline1,
                                          callback: function($$v) {
                                            _vm.addressline1 = $$v
                                          },
                                          expression: "addressline1"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("addressline1"),
                                        expression: "errors.has('addressline1')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("addressline1"),
                                          expression:
                                            "errors.has('addressline1')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The address is required. Must contain numbers and letters."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "addressline2",
                                          placeholder: "Address Line 2"
                                        },
                                        model: {
                                          value: _vm.addressline2,
                                          callback: function($$v) {
                                            _vm.addressline2 = $$v
                                          },
                                          expression: "addressline2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "city",
                                          placeholder: "City"
                                        },
                                        model: {
                                          value: _vm.city,
                                          callback: function($$v) {
                                            _vm.city = $$v
                                          },
                                          expression: "city"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("city"),
                                        expression: "errors.has('city')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("city"),
                                          expression: "errors.has('city')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The city is required.")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "select",
                                          options: _vm.provinceOptions,
                                          name: "province",
                                          placeholder: "Province"
                                        },
                                        model: {
                                          value: _vm.province,
                                          callback: function($$v) {
                                            _vm.province = $$v
                                          },
                                          expression: "province"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("province"),
                                        expression: "errors.has('province')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("province"),
                                          expression: "errors.has('province')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The province is required. Max 6 characters."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|max:6",
                                            expression: "'required|max:6'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "postalcode",
                                          placeholder: "Postal Code"
                                        },
                                        model: {
                                          value: _vm.postalcode,
                                          callback: function($$v) {
                                            _vm.postalcode = $$v
                                          },
                                          expression: "postalcode"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("postalcode"),
                                        expression: "errors.has('postalcode')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("postalcode"),
                                          expression: "errors.has('postalcode')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The postal code is required.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "rcn",
                                          placeholder:
                                            "Registration Company Number"
                                        },
                                        model: {
                                          value: _vm.rcn,
                                          callback: function($$v) {
                                            _vm.rcn = $$v
                                          },
                                          expression: "rcn"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("rcn"),
                                        expression: "errors.has('rcn')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("rcn"),
                                          expression: "errors.has('rcn')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The company number is required.")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3 mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-building"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "gst",
                                          placeholder: "GST Number"
                                        },
                                        model: {
                                          value: _vm.gst,
                                          callback: function($$v) {
                                            _vm.gst = $$v
                                          },
                                          expression: "gst"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("gst"),
                                        expression: "errors.has('gst')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("gst"),
                                          expression: "errors.has('gst')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("The GST number is required.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-3 mb-3 pb-0" },
                            [_vm._v("Contact Information")]
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-phone fa-flip-horizontal"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|digits:10",
                                            expression: "'required|digits:10'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "phone_num",
                                          placeholder: "Phone Number"
                                        },
                                        model: {
                                          value: _vm.phone_num,
                                          callback: function($$v) {
                                            _vm.phone_num = $$v
                                          },
                                          expression: "phone_num"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("phone_num"),
                                        expression: "errors.has('phone_num')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("phone_num"),
                                          expression: "errors.has('phone_num')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        "The phone number is required. Must be 10 digits."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-fax"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "digits:10",
                                            expression: "'digits:10'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "fax_num",
                                          placeholder: "Fax Number"
                                        },
                                        model: {
                                          value: _vm.fax_num,
                                          callback: function($$v) {
                                            _vm.fax_num = $$v
                                          },
                                          expression: "fax_num"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("fax_num"),
                                        expression: "errors.has('fax_num')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("fax_num"),
                                          expression: "errors.has('fax_num')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("Must be 10 digits.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "h4",
                            { staticClass: "text-muted mt-3 mb-3 pb-0" },
                            [_vm._v("Login Information")]
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _vm._v("@")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.email,
                                            expression: "email"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|email",
                                            expression: "'required|email'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "email",
                                          placeholder: "Email",
                                          name: "email",
                                          autocomplete: "email"
                                        },
                                        domProps: { value: _vm.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.email = $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("email"),
                                        expression: "errors.has('email')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("email"),
                                          expression: "errors.has('email')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-lock"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password,
                                            expression: "password"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|min:6",
                                            expression: "'required|min:6'"
                                          }
                                        ],
                                        ref: "password",
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "password",
                                          placeholder: "Password",
                                          name: "password"
                                        },
                                        domProps: { value: _vm.password },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password = $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("password"),
                                        expression: "errors.has('password')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("password"),
                                          expression: "errors.has('password')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("password"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-input-group-prepend",
                                        [
                                          _c("b-input-group-text", [
                                            _c("i", {
                                              staticClass: "fa fa-lock"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password2,
                                            expression: "password2"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "required|confirmed:password",
                                            expression:
                                              "'required|confirmed:password'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "password",
                                          placeholder: "Password Confirmation",
                                          "data-vv-as": "password",
                                          name: "password2"
                                        },
                                        domProps: { value: _vm.password2 },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password2 = $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("i", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("password2"),
                                        expression: "errors.has('password2')"
                                      }
                                    ],
                                    staticClass: "fa fa-exclamation-triangle"
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("password2"),
                                          expression: "errors.has('password2')"
                                        }
                                      ],
                                      staticClass: "help is-danger"
                                    },
                                    [_vm._v("Passwords must match.")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-input-group",
                            [
                              _c("vue-recaptcha", {
                                ref: "reCaptcha",
                                staticClass: "mt-2",
                                attrs: {
                                  sitekey:
                                    "6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6"
                                },
                                on: {
                                  verify: _vm.onCaptchaVerified,
                                  expired: _vm.onCaptchaExpired
                                }
                              })
                            ],
                            1
                          ),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("robot"),
                                expression: "errors.has('robot')"
                              }
                            ],
                            staticClass: "fa fa-exclamation-triangle mt-2 mb-2"
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("robot"),
                                  expression: "errors.has('robot')"
                                }
                              ],
                              staticClass: "mb-3 help is-danger"
                            },
                            [_vm._v("The reCaptcha is required.")]
                          ),
                          _c("div", { staticClass: "clearfix" }, [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.error,
                                  expression: "error"
                                }
                              ],
                              staticClass: "fa mt-3 fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.error,
                                    expression: "error"
                                  }
                                ],
                                staticClass: "mb-3 help is-danger"
                              },
                              [_vm._v("Signup failed. Please contact admin.")]
                            )
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                variant: "primary",
                                type: "submit",
                                block: ""
                              }
                            },
                            [_vm._v("Create Account")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }