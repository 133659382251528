<template>
  <div class="app flex-row align-items-center guest">
    <div class="container pt-4 pb-4">
      <b-row class="justify-content-center">
        <b-col md="8" lg="10">
          <b-card no-body class="">
            <b-card-body class="p-4">
              <form @submit.prevent="validateBeforeSubmit">
                <router-link :to="{ name: 'Login' }">
                  <img class="mx-auto d-block mb-4 navbar-brand-full" src="img/brand/invoice-logo-2019.png" height="95" alt="RTNEST Inc. Logo">
                </router-link>
                <h1 class="h2">Register Well Site Supervisor</h1>
                <h4 class="text-muted mt-3 mb-0 pb-0">Name</h4>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-1">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
                    </b-input-group>
                    <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-1">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
                    </b-input-group>
                    <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
                  </b-col>
                </b-row>

                <hr>
                <h4 class="text-muted mt-1 mb-0 pb-0">Business Information</h4>

                <b-row>
                  <b-col md="12">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="company_name" v-validate="'required'" name="company_name" class="form-control" placeholder="Company Name" />
                    </b-input-group>
                    <i v-show="errors.has('company_name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('company_name')" class="help is-danger">The company name is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="addressline1" v-validate="'required'" name="addressline1" class="form-control" placeholder="Address Line 1" />
                    </b-input-group>
                    <i v-show="errors.has('addressline1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addressline1')" class="help is-danger">The address is required. Must contain numbers and letters.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="addressline2" name="addressline2" class="form-control" placeholder="Address Line 2" />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="city" v-validate="'required'" name="city" class="form-control" placeholder="City" />
                    </b-input-group>
                    <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')" class="help is-danger">The city is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-select type="select" :options="provinceOptions" v-model="province" v-validate="'required'" name="province" class="form-control" placeholder="Province"/>
                    </b-input-group>
                    <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('province')" class="help is-danger">The province is required. Max 6 characters.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="postalcode" v-validate="'required|max:6'" name="postalcode" class="form-control" placeholder="Postal Code" />
                    </b-input-group>
                    <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="rcn" v-validate="'required'" name="rcn" class="form-control" placeholder="Registration Company Number" />
                    </b-input-group>
                    <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('rcn')" class="help is-danger">The company number is required.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mt-3 mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="gst" v-validate="'required'" name="gst" class="form-control" placeholder="GST Number" />
                    </b-input-group>
                    <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('gst')" class="help is-danger">The GST number is required.</span>
                  </b-col>
                </b-row>
                <hr>
                <h4 class="text-muted mt-3 mb-3 pb-0">Contact Information</h4>
                <b-row>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="phone_num" v-validate="'required|digits:10'" name="phone_num" class="form-control" placeholder="Phone Number" />
                    </b-input-group>
                    <i v-show="errors.has('phone_num')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone_num')" class="help is-danger">The phone number is required. Must be 10 digits.</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" v-model="fax_num" name="fax_num" v-validate="'digits:10'" class="form-control" placeholder="Fax Number" />
                    </b-input-group>
                    <i v-show="errors.has('fax_num')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax_num')" class="help is-danger">Must be 10 digits.</span>
                  </b-col>
                </b-row>
                <hr>
                <h4 class="text-muted mt-3 mb-3 pb-0">Login Information</h4>

                <b-row class="mb-3">
                  <b-col md="12">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text>@</b-input-group-text>
                      </b-input-group-prepend>
                      <input type="email" class="form-control" v-model="email" v-validate="'required|email'" placeholder="Email" name="email" autocomplete="email" />
                    </b-input-group>
                    <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <input type="password" class="form-control" v-model="password" v-validate="'required|min:6'" placeholder="Password" name="password" ref="password"/>
                    </b-input-group>
                    <i v-show="errors.has('password')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>
                  </b-col>
                  <b-col md="6">
                    <b-input-group class="mb-2">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <input type="password" class="form-control" v-model="password2" v-validate="'required|confirmed:password'" placeholder="Password Confirmation" data-vv-as="password" name="password2" />
                    </b-input-group>
                    <i v-show="errors.has('password2')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('password2')" class="help is-danger">Passwords must match.</span>
                  </b-col>
                </b-row>
                <!--
                  <input type="hidden" name="robot" v-model="robot" v-validate="'required'" />
                  -->
                <b-input-group>
                  <vue-recaptcha ref="reCaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" class="mt-2" sitekey="6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6">
                  </vue-recaptcha>
                </b-input-group>
                <i v-show="errors.has('robot')" class="fa fa-exclamation-triangle mt-2 mb-2"></i> <span v-show="errors.has('robot')" class="mb-3 help is-danger">The reCaptcha is required.</span>

                <div class="clearfix"><i v-show="error" class="fa mt-3 fa-exclamation-triangle"></i><span v-show="error" class="mb-3 help is-danger">Signup failed. Please contact admin.</span></div>
                <b-button variant="primary" class="mt-4" type="submit" block>Create Account</b-button>
              </form>
            </b-card-body>
          </b-card>
        </b-col>

      </b-row>
    </div>
  </div>
</template>


<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'RegisterWss',
  components: {
    VueRecaptcha
  },
  data: function () {
    return {
      robot: '',
      email: '',
      fname: '',
      lname: '',
      company_name: '',
      rcn: '',
      phone_num: '',
      fax_num: '',
      gst: '',
      addressline1: '',
      addressline2: '',
      postalcode: '',
      city: '',
      province: null,
      password: '',
      password2: '',
      error: false,
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: "Alberta", text: 'Alberta'},
        { value: "British Columbia", text: 'British Columbia'},
        { value: "Manitoba", text: 'Manitoba'},
        { value: "New Brunswick", text: 'New Brunswick'},
        { value: "Newfoundland and Labrador", text: 'Newfoundland and Labrador'},
        { value: "Nova Scotia", text: 'Nova Scotia'},
        { value: "Ontario", text: 'Ontario'},
        { value: "Prince Edward Island", text: 'Prince Edward Island'},
        { value: "Quebec", text: 'Quebec'},
        { value: "Saskatchewan", text: 'Saskatchewan'},
        { value: "Northwest Territories", text: 'Northwest Territories'},
        { value: "Nunavut", text: 'Nunavut'},
        { value: "Yukon", text: 'Yukon'}
      ]
    }
  },
  methods: {
    onCaptchaExpired: function () {
      this.robot = '';
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.robot = 'true';
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            email: this.email,
            fname: this.fname,
            lname: this.lname,
            company_name: this.company_name,
            rcn: this.rcn,
            phone_num: this.phone_num,
            fax_num: this.fax_num,
            gst: this.gst,
            addressline1: this.addressline1,
            addressline2: this.addressline2,
            city: this.city,
            postalcode: this.postalcode,
            province: this.province,
            password: this.password,
            Pid:this.$route.query.pid
          });
         
          this.$axios.post('/wss/signup', data)
          .then(response => {
            if (response.status === 200) {
              this.flash({ message: 'Registration Successful', variant: 'success' });
              this.$router.push('/')
            }
          })
          .catch(error => {
            this.error = true;
            this.robot = '';
            this.$refs.reCaptcha.reset();
          })
          return;
        }
      });
    }
  }
}
</script>
